import React from 'react';


import Header from './components/header/header';

import SearchCategory from './components/search-category';
import PagesProducts from './components/pagesproducts';
import Announcement from './components/header/announcement';
import AnnouncementNew from './components/header/announcement-new';
import { UilUser } from '@iconscout/react-unicons';
import HeaderNew from './components/header/header-new';

function Main(args) {

  /*return (
    <section>
    <Banner></Banner>
    <PageBody></PageBody>
    </section>
);*/

return (
  <div className="">
        <Announcement/>
        <AnnouncementNew/>
        <div className='z-20'>
          <Header page="acasa"/>
        </div>
        
        <div className='relative  z-10'>
          <SearchCategory productPath={args.productPath} categoryPath={args.categoryPath}/>
        </div >
        <div className='relative z-0'>
          <PagesProducts productPath={args.productPath}/>
        </div>
        <div className="bg-black w-full flex p-2">
                <span className="text-white text-sm items-center m-auto">Copyright © 2024 Crangeio S.R.L.</span>
            </div>
  </div>
);

//lg:sticky lg:top-0
    
}
export default Main;

/*

<div className="flex flex-row w-full bg-header p-4 z-20 sticky top-0 shadow shadow-xl">
          <div className='relative w-3/4 m-auto flex flex-row   '>
            <div className="text-center flex m-auto p-4 sm:w-3/4 md:w-1/2 lg:w-1/2 rounded-xl bg-white">
                    <span className='m-auto'>
                        Pentru a afla noutăți legate de produsele din magazin, <a href="https://www.facebook.com/profile.php?id=61556917665673" className="font-semibold underline">urmăriți-ne pe Facebook</a>!
                    </span>
            </div>
            <div className='absolute flex flex-row gap-2 right-0 p-2 bg-white rounded-full'>
              <span className='m-auto pl-2'>Bine ai venit, <span className='font-semibold'>George</span></span>
              <div className='flex p-2 bg-black aspect-square rounded-full'>
                <UilUser color={'white'}></UilUser>
              </div>
              
            </div>
            </div>
        </div>

return (
        <section className="page-body">
          <Header></Header>
          <section className="product-list">
            <section className="placeholder-up"></section>
            <PageContainerUp></PageContainerUp>
            <SearchBar></SearchBar>
            <CategoryContainer> </CategoryContainer>
            <ProductContainer> </ProductContainer>
            <section className="placeholder-down"></section>
            <PageContainerDown></PageContainerDown>
          </section>
        </section>
    );

*/
