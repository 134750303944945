import Header from "./components/header/header"
import Announcement from "./components/header/announcement"
import AnnouncementNew from "./components/header/announcement-new"
//AIzaSyCIjbIE0P7vAMtQoh-kmv2iEED0qmjPAdM

import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { CustomMapStyle } from "./data/maps-styles";
import { UilFacebook } from '@iconscout/react-unicons'
import { UilInstagram } from '@iconscout/react-unicons'
import HeaderNew from "./components/header/header-new";

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '320px',
};
const defaultMapOptions = {
    clickableIcons: true,
    styles: CustomMapStyle,
    disableDefaultUI: true,
    fullscreenControl: false,
}
const center = {
  lat: 47.06026346041849, // default latitude
  lng: 21.91907950206995, // default longitude
};

export default function Contact () {

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCIjbIE0P7vAMtQoh-kmv2iEED0qmjPAdM',
        libraries,
      });
    
      if (loadError) {
        return <div>Error loading maps</div>;
      }
    
      if (!isLoaded) {
        return (
            <div>

            </div> 
        )
      }

      const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };

    return (
        <div>
            <Announcement/>
            <AnnouncementNew/>
            <Header page="unde-ne-gasesti"/>
            <div className=" bg-products py-8">
                <div className=" m-auto rounded-lg bg-white w-[90%] flex flex-col p-8">
                    
                    <div className="flex flex-col text-justify mx-auto gap-4 w-full md:w-[80%] xl:w-[60%]">
                        
                        <div className="ml-0 text-left text-xl pb-2 border-b-2">
                            <span className="font-bold">Program</span>
                        </div>
                        <div className="flex text-sm md:text-base mt-2">
                            
                            <div className="m-auto lg:ml-0 w-fit  p-4 grid grid-cols-1 grid-rows-7 divide-x-2 divide-y-2 rounded-lg border-solid border-2 ">
                                <div className="border-b-2  pb-2 flex gap-4">
                                    <span className="font-semibold">Luni</span>
                                    <span className="ml-auto mr-0">12:00 - 19:00</span>
                                </div>
                                <div className="border-b-2  py-2 flex gap-4">
                                    <span className="font-semibold">Marți</span>
                                    <span className="ml-auto mr-0">12:00 - 19:00</span>
                                </div>
                                <div className="border-b-2  py-2 flex gap-4">
                                    <span className="font-semibold">Miercuri</span>
                                    <span className="ml-auto mr-0">12:00 - 19:00</span>
                                </div>
                                <div className="border-b-2  py-2 flex gap-4">
                                    <span className="font-semibold ml-0">Joi</span>
                                    <span className="ml-auto mr-0">12:00 - 19:00</span>
                                </div>
                                <div className="border-b-2  py-2 flex gap-4">
                                    <span className="font-semibold">Vineri</span>
                                    <span className="ml-auto mr-0">12:00 - 19:00</span>
                                </div>
                                <div className="border-b-2  py-2 flex gap-4">
                                    <span className="font-semibold">Sâmbătă</span>
                                    <span className="ml-auto mr-0">10:00 - 15:00</span>
                                </div>
                                <div className="  pt-2 flex gap-4">
                                    <span className="font-semibold">Duminică</span>
                                    <span className="ml-auto mr-0">Închis</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col text-justify mx-auto mt-8 gap-4 w-full md:w-[80%] xl:w-[60%]">
                        <div className="ml-0 text-left text-xl pb-2 border-b-2">
                            <span className="font-bold">Locație</span>
                        </div>
                        <div className="mx-auto flex flex-col gap-4 lg:flex-row w-full object-contain max-w-fit mt-2">
                            <div className="lg:w-1/2 md:w-full rounded-lg overflow-hidden  border-4 border-category/50 flex">
                                <GoogleMap
                                options={defaultMapOptions}
                                mapContainerStyle={mapContainerStyle}
                                zoom={18}
                                center={center}
                                >
                                    <Marker position={center} />
                                    </GoogleMap>    
                            </div>
                            <div className="flex flex-col gap-6 ">
                                <div className="flex flex-col gap-2">
                                    <span className="font-semibold">Adresă</span>
                                    <span>Bulevardul Decebal nr. 52 (colț cu Tudor Vladimirescu)</span>
                                    <span>Oradea 410205, Bihor, România</span>
                                </div>
                                <div className="flex flex-col gap-2 ">
                                    <span className="font-semibold">Detalii contact</span>
                                    <span>+40 774 693 485</span>
                                    <a href="mailto:contact@stockzilla.ro">contact@stockzilla.ro</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col text-justify mx-auto mt-8 gap-4 w-full md:w-[80%] xl:w-[60%]">
                        
                        <div className="ml-0 text-left text-xl pb-2 border-b-2">
                            <span className="font-bold">Social Media</span>
                        </div>
                        <div className="flex flex-col gap-4 text-sm md:text-base mt-2">
                            
                            <button 
                            onClick={() => {
                                openInNewTab("https://www.facebook.com/profile.php?id=61556917665673");
                            }}
                            className="flex w-full md:w-[25%] p-4 gap-2 rounded-md bg-gray-200 text-black hover:text-white hover:bg-black duration-200">
                                <div className="my-auto"><UilFacebook/></div>
                                <span className="my-auto ml-0">Facebook</span>
                            </button>
                            <button 
                            onClick={() => {
                                openInNewTab("https://www.instagram.com/stockzillaretail/");
                            }}
                            className="align-center items-center flex w-full md:w-[25%] gap-2 p-4 rounded-md bg-gray-200 text-black hover:text-white hover:bg-black duration-200">
                                <div className="my-auto"><UilInstagram/></div>
                                <span className="my-auto ml-0">Instagram</span>
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

/* 

<div className="p-2 flex flex-col text-center rounded-md bg-green-200 ring-1 ring-header">
                            <span className="m-auto text-header text-sm md:text-base">Data oficială de deschidere a magazinului este <b>1 aprilie</b>.</span>
                            <span className="m-auto text-header text-sm md:text-base">Te așteptăm la noi în magazin!</span>
                        </div>

<iframe title="stockzilla" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d404.0304866448575!2d21.918909851280535!3d47.060197490402665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474647936625dee1%3A0xb075be4f1b9c5569!2sStockzilla!5e0!3m2!1sen!2sro!4v1710189201729!5m2!1sen!2sro" 
                        width="600" height="450" allowfullscreen="" 
                        loading="lazy" 
                        style={}
                        referrerpolicy="no-referrer-when-downgrade">
                        </iframe>


<div className="p-4 grid grid-cols-2 gap-4 grid-rows-7 rounded-md border-solid border-2">
                                <span>Luni</span>
                                <span>12:00 - 19:00</span>
                                <span>Marți</span>
                                <span>12:00 - 19:00</span>
                                <span>Miercuri</span>
                                <span>12:00 - 19:00</span>
                                <span>Joi</span>
                                <span>12:00 - 19:00</span>
                                <span>Vineri</span>
                                <span>12:00 - 19:00</span>
                                <span>Sâmbătă</span>
                                <span>10:00 - 15:00</span>
                                <span>Duminică</span>
                                <span>Închis</span>
                            </div>

*/