import Header from "./components/header/header"
import Announcement from "./components/header/announcement"
import AccordionRetururi from "./components/accordion-retururi"
import AnnouncementNew from "./components/header/announcement-new"
import { textRetururi } from "./data/text-retururi"
import HeaderNew from "./components/header/header-new"

export default function DeCeRetururi () {
    return (
        <div>
            <Announcement/>
            <AnnouncementNew/>
            <Header page="de-ce-retururi"/>
            <div className=" min-h-screen bg-products py-8">
                <div className="  m-auto rounded-lg bg-white w-[90%] flex flex-col p-8">
                    <div className="flex flex-col text-justify mx-auto gap-4 w-full md:w-[80%] xl:w-[60%]">
                        <div className="ml-0 text-left text-xl pb-2 border-b-2">
                            <span className="font-bold">De ce să cumperi produse provenite din retururi?</span>
                        </div>
                        <div className="flex flex-col gap-4 text-sm md:text-base mt-2">
                            <div>
                                <p>
                                În era modernă a consumerismului, cumpărăturile on-line au revoluționat modul în care facem cumpărături.
                                Din confortul caselor noastre, avem acces la milioane de produse pe care le putem cumpăra doar cu un click.
                                Deși foarte convenabil, acest obicei are un impact negativ atât asupra mediului cât și a buzunarelor noastre.
                                Cum ar fi să existe o cale prin care să poți economisi bani și să reduci impactul asupra mediului?
                                Ei bine, poți face asta, cumpărând produse provenite din retururi pe care le găsești în magazinul Stockzilla.
                                </p>
                            </div>
                            <div>
                                <p>
                                Hai să vedem cum, cumpărând produse provenite din retururi de marfă, este atât o alegere prietenoasă cu mediul,
                                cât și una care te ajută să economisești.
                                </p>
                            </div>
                            {textRetururi.map((item) => {
                                return (<AccordionRetururi title={item.title} description={item.description}/>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}