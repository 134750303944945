
const queryParameters = new URLSearchParams(window.location.search)
    var urlcategory = queryParameters.get('category');
    var urlpage = parseInt(queryParameters.get('page'));
    var urlquery = queryParameters.get('search');
    var filter1 = queryParameters.get('filter1');
    var filter2 = queryParameters.get('filter2');
    var inMagazin = queryParameters.get('inMagazin');
    
    var locationRoot = "";
    var productCount = 0;
    //console.log (window.location.pathname.slice('product/')[0]);
    switch (window.location.pathname.slice('product')[0]) {
      default: break;
      case '/cevacodsecret': {
        locationRoot = "/cevacodsecret";
        break;
      }
      case '/cevacodsecret/': {
        locationRoot = "/cevacodsecret";
        break;
      }
      case '/altcodsecret': {
        locationRoot = "/altcodsecret";
        break;
      }
      case '/altcodsecret/': {
        locationRoot = "/altcodsecret";
        break;
      }
      case '/toateloturile': {
        locationRoot = "/toateloturile";
        break;
      }
      case '/toateloturile/': {
        locationRoot = "/toateloturile";
        break;
      }
    }
    if (isNaN(urlpage) || urlpage <= 0) {
      urlpage = 1;
    }
    if (urlcategory == null) {
      urlcategory = "";
    }
    if (urlquery == null) {
      urlquery = "";
    }
    if (filter1 == null) {
      filter1 = "0";
    }
    if (filter2 == null) {
      filter2 = "0";
    }
    if (inMagazin == null) {
      inMagazin = "0";
    }

    

    function getPage () {
      return urlpage;
    }
    function getCategory () {
      return urlcategory;
    }
    function getQuery () {
      return urlquery;
    }

    

    export {urlcategory, urlquery, urlpage, filter1, filter2, inMagazin, locationRoot, productCount, getPage, getCategory, getQuery}